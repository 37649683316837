<template>
    <!-- SECTION CONTENT START -->
    <div class="section-full p-tb90 bg-gray">
        <div class="container-fluid">        
        <!-- TITLE START -->
            <div class="section-head text-center">
                <h2 class="m-b5">Party Halls at KRV Meridian</h2>
                <div class="wt-separator-outer">
                    <div class="wt-separator bg-primary"></div>
                </div>
                    <p>KRV is ideal for any corporate or family event. Celebrate your event with trusted vendors and well trained professional staffs in KRV meridian.</p>
            </div>
            <!-- TITLE END -->              
            <div class="section-content">            
                <div class="work-carousel-outer">
                    <div class="owl-carousel work-carousel owl-btn-vertical-center">
                        <!-- COLUMNS 1 -->
                        <div class="item">
                            <div class="wt-img-effect ">
                                <img src="images/party-halls/krv-harmony-hall-karur.jpg" alt="KRV Harmony Hall, Karur" />
                                <div class="wt-info overflow-hide">
                                    <h4 class=" m-b10 m-t0"><a href="javascript:void(0);">Harmony Hall</a></h4>
                                    <span class="text-primary">Conference/Banquet</span>
                                    <p>KRV is ideal for any corporate or family event. celebrate your event with trusted vendors and well trained professional staffs in KRV meridian.
                                    </p>
                                    <ul class="widget_address" style="list-style-type: none;">
                                        <li><i class="sl-icon-people text-primary"></i>Occupancy: 220 Guests</li>
                                        <li><i class="sl-icon-user-follow text-primary"></i>Floating: 250 Guests</li>
                                        <li><i class="flaticon-room-service text-primary"></i>Food: Veg and Non-Veg</li>
                                    </ul>
                        
                                    <a href="contact-us" class="btn-half site-button button-lg m-b15"><span>Contact Us</span><em></em></a>
                                </div>
                            </div>
                        </div>
                         <div class="item">
                            <div >
                                <img src="images/party-halls/krv-harmony-hall-karur-big.jpg" alt="KRV Harmony Hall, Karur" />
                               
                               
                            </div>
                        </div>
                        <!-- COLUMNS 2 -->
                        <div class="item">
                            <div class="wt-img-effect ">
                                <img src="images/party-halls/krv-mahal-karur.jpg" alt="KRV Mahal, Karur" />
                                <div class="wt-info overflow-hide">
                                    <h4 class=" m-b10 m-t0"><a href="javascript:void(0);">KRV Mahal</a></h4>
                                    <span class="text-primary">Party/Marriage</span>
                                    <p>KRV is ideal for any corporate or family event. celebrate your event with trusted vendors and well trained professional staffs in KRV meridian.
                                    </p>
                                    <ul class="widget_address"  style="list-style-type: none;">
                                        <li><i class="sl-icon-people text-primary"></i>Occupancy: 450 Guests</li>
                                        <li><i class="sl-icon-user-follow text-primary"></i>Floating: 500 Guests</li>
                                        <li><i class="flaticon-room-service text-primary"></i>Food: Veg and Non-Veg</li>
                                    </ul>
                                        <a href="contact-us" class="btn-half site-button button-lg m-b15"><span>Contact Us</span><em></em></a>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div class="item">
                            <div >
                                <img src="images/party-halls/krv-mahal-karur-big.jpg" alt="KRV Mahal, Karur" />
                               
                               
                            </div>
                        </div>
                  
                                                                                                                                                        
                        </div>
                </div> 
                </div>
            </div>
        </div>           
    <!-- SECTION CONTENT END  -->
</template>
<script>
export default {
    
}
</script>