<template>
    <div class="page-content">
        <Banner />
        <Story />
        <Gallery />
    </div>
</template>
<script>
import Banner from './banner.vue'
import Story from './story.vue'
import Gallery from './gallery.vue'
export default {
    components:{        
        Banner, Story, Gallery
    }
}
</script>