<template>
    <!-- TITLE START -->
    <div class="section-head text-center">
        <h2 class="m-b5">Fitness at KRV Meridian</h2>
        <div class="wt-separator-outer">
            <div class="wt-separator bg-primary"></div>
        </div>
        <p>We have provide the best fitness center during your visit, to make you fit. We have 150+ gym equipments with a trainner. </p>
    </div>
    <!-- TITLE END -->    
</template>
<script>
export default {
    
}
</script>