<template>
    <!-- CONTENT START -->
    <div class="page-content">
        <!-- INNER PAGE BANNER -->
        <div class="wt-bnr-inr overlay-wraper bg-parallax bg-top-center"  data-stellar-background-ratio="0.5"  style="background-image:url(images/breadcrumb/guest-rooms-bc-image.jpg);">
            <div class="overlay-main bg-black opacity-07"></div>
            <div class="container">
                <div class="wt-bnr-inr-entry">
                    <div class="banner-title-outer">
                        <div class="banner-title-name">
                            <h2 class="text-white  font-80 font-weight-900">Super Deluxe Rooms</h2>
                        </div>
                    </div>
                    <!-- BREADCRUMB ROW -->                          
                    <div>
                        <ul class="wt-breadcrumb breadcrumb-style-2">
                            <li><a href="/">Home</a></li>
                            <li>Super Deluxe Rooms</li>
                        </ul>
                    </div>
                    <!-- BREADCRUMB ROW END -->                        
                </div>
            </div>
        </div>
        <!-- INNER PAGE BANNER END --> 
        <div class="section-full p-tb90 ">
            <div class="container">
                <div class="room-detail-outer">
                    <div class="wt-post-media">                     
                        <h2>Super Deluxe Room</h2>
                        <!--Fade slider-->
                        <div class="owl-carousel owl-fade-slider-one owl-btn-vertical-center owl-dots-bottom-right m-b30">
                            <div class="item">
                                <div class="wt-thum-bx">
                                    <img src="images/guest-rooms/deluxe-room/deluxe-room-photo-1.jpg" alt="deluxe-room-photo" />
                                </div>
                            </div>                            
                            <div class="item">
                                <div class="wt-thum-bx">
                                    <img src="images/guest-rooms/deluxe-room/deluxe-room-photo-2.jpg" alt="deluxe-room-photo" />
                                </div>
                            </div>                         
                            <div class="item">
                                <div class="wt-thum-bx">
                                    <img src="images/guest-rooms/deluxe-room/deluxe-room-photo-4.jpg" alt="deluxe-room-photo" />
                                </div>
                            </div>                         
                        </div>
                        <!--fade slider END-->
                        <div class="room-facility">
                            <div class="room-discription">                                
                                <h4>from $399.00/night</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
                            </div>
                            <div class="room-amenities m-b30">
                                <h3>Amenities</h3>
                                <div class="amenities-list equal-wraper clearfix">
                                    <ul>
                                        <li class="equal-col" style="height: 32px;"><h5 class="m-b0"> Double bed</h5></li>
                                        <li class="equal-col" style="height: 32px;"><h5 class="m-b0">Balcony</h5></li>
                                        <li class="equal-col" style="height: 32px;"><h5 class="m-b0">Bathroom</h5></li>
                                        <li class="equal-col" style="height: 32px;"><h5 class="m-b0">Shower</h5></li>
                                        <li class="equal-col" style="height: 32px;"><h5 class="m-b0">Shampoo and soap</h5></li>
                                        <li class="equal-col" style="height: 32px;"><h5 class="m-b0">Hairdryer</h5></li>
                                        <li class="equal-col" style="height: 32px;"><h5 class="m-b0">Slippers</h5></li>
                                        <li class="equal-col" style="height: 32px;"><h5 class="m-b0">Wardrobe</h5></li>
                                        <li class="equal-col" style="height: 32px;"><h5 class="m-b0">Working table</h5></li>
                                        <li class="equal-col" style="height: 32px;"><h5 class="m-b0">Mini bar</h5></li>
                                        <li class="equal-col" style="height: 32px;"><h5 class="m-b0">Satellite TV</h5></li>
                                        <li class="equal-col" style="height: 32px;"><h5 class="m-b0">Telephone</h5></li>
                                        <li class="equal-col" style="height: 32px;"><h5 class="m-b0">Wireless connect</h5></li>
                                        <li class="equal-col" style="height: 32px;"><h5 class="m-b0">Air conditioner</h5></li>
                                        <li class="equal-col" style="height: 32px;"><h5 class="m-b0">220 AC</h5></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="room-Rates m-b30">
                                <h3>Rates</h3>
                                <div id="no-more-tables">
                                    <table class="col-md-12 table-bordered table-striped table-condensed cf wt-responsive-table">
                                        <thead class="cf">
                                            <tr>
                                                <th>Season</th>
                                                <th>Date</th>
                                                <th class="numeric">Charges</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-title="Season">Main season 1</td>
                                                <td data-title="Date">01 April – 30 June 2019</td>
                                                <td data-title="Charges" class="numeric">$299.00/night</td>        
                                            </tr>
                                            <tr>
                                                <td data-title="Season">School holidays</td>
                                                <td data-title="Date">10 Feb - 20 Mar 2019</td>
                                                <td data-title="Charges" class="numeric">$299.00/night</td>
                                            </tr>
                                            <tr>
                                                <td data-title="Season">Weekend only</td>
                                                <td data-title="Date">Thursday through Sunday</td>
                                                <td data-title="Charges" class="numeric">$399.00/night</td>
                                            </tr>
                                            <tr>
                                                <td data-title="Season">Christmas &amp; New Year's</td>
                                                <td data-title="Date">20 Dec 2019 - 10 Jan 2020</td>
                                                <td data-title="Charges" class="numeric">$499.00/night</td>
                                            </tr>                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>                         
                        </div>
                    </div>
                </div>                                     
            </div>
        </div>
        <!-- SECTION CONTENT END -->       
    </div>
    <!-- CONTENT END -->
</template>
<script>
export default {
    
}
</script>