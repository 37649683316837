<template>
    <Gamezone />
</template>
<script>
import Gamezone from '../components/Game-zone/index.vue'
export default {
    components:{
        Gamezone
    },
    metaInfo: {
        title: 'GYM Infrastruture at KRV Meridian Hotel, Karur.'
    }
}
</script>