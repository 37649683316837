<template>
    <div>
        <!-- HEADER START -->
        <header class="site-header header-style-1">
            <div class="sticky-header main-bar-wraper">
                <div class="main-bar bg-white p-t5">
                    <div class="container">
                        <div class="logo-header">
                            <div class="logo-header-inner logo-header-one">
                                <a href="/"> 
                                    <img src="images/krv-logo-white.png" alt="" />
                                </a>
                            </div>
                        </div>
                        <!-- NAV Toggle Button -->
                        <button data-target=".header-nav" data-toggle="collapse" type="button" class="navbar-toggle collapsed">
                            <span class="sr-only">Toggle navigation</span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>
                        <!-- ETRA Nav -->
                        <div class="extra-nav">
                            <div class="extra-cell">
                                <a href="#" class="contact-slide-show text-white"><i class="fa fa-envelope-o"></i></a>
                            </div>    
                            
                            <div class="extra-cell">   
                                <a href="javascript:;" class="socialicon_show  text-white">
                                    <i class="fa fa-share-alt"></i>
                                </a>
                            </div>   	
                        </div>
                        <!-- ETRA Nav -->                               
                        <!-- Social Nav -->
                        <div class="social_hide"> 
                            <div class="side-social-nav">
                                    <a href="javascript:void(0)" class="socialicon_close">&times;</a>
                                    <ul class="list-unstyled">
                                    <li><a href="javascript:void(0);" class="fa fa-facebook"><span class="social-tooltip">Facebook</span></a></li>
                                <li><a href="javascript:void(0);" class="fa fa-twitter"><span class="social-tooltip">Twitter</span></a></li>                            
                                <li><a href="javascript:void(0);" class="fa fa-linkedin"><span class="social-tooltip">Linkedin</span></a></li>
                                <li><a href="javascript:void(0);" class="fa fa-instagram"><span class="social-tooltip">Instagram</span></a></li>
                                </ul>
                            </div> 
                        </div>
                        <!-- Contact Nav -->                            
                        <div class="contact-slide-hide"> 
                            <div class="contact-nav">
                                <a href="javascript:void(0)" class="contact_close">&times;</a>
                                <div class="contact-nav-form p-a30">
                                    <form class="cons-contact-form" method="post" action="form-handler.php">
                                        <div class="contact-one m-b30">                                        
                                            <!-- TITLE START -->
                                            <div class="section-head text-center">
                                                <h2 class="m-b5" data-title="Form">Get In Touch</h2>
                                            </div>
                                            <!-- TITLE END -->  
                                            <div class="row">
                                                <div class="col-md-12">                               
                                                    <div class="form-group wt-inputicon-box">
                                                        <input name="username" type="text" required class="form-control" placeholder="Name" v-model="t_contact.t_username">
                                                        <i class="fs-input-icon sl-icon-user"></i>
                                                    </div>                                                    
                                                    <div class="form-group" id="t_username-alert">
                                                            
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group wt-inputicon-box"> 
                                                        <input name="mobile" type="text" class="form-control" required placeholder="Mobile" v-model="t_contact.t_mobile">
                                                        <i class="fs-input-icon sl-icon-phone"></i>
                                                    </div>                                                     
                                                    <div class="form-group" id="t_mobile-alert">
                                                            
                                                    </div>                                                   
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group wt-inputicon-box"> 
                                                        <input name="email" type="text" class="form-control" required placeholder="Email" v-model="t_contact.t_email">
                                                        <i class="fs-input-icon sl-icon-envolope-letter"></i>
                                                    </div>                                                    
                                                </div>
                                                <div class="form-group" id="t_email-alert">
                                                        
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group wt-inputicon-box">
                                                        <textarea name="message" rows="3" class="form-control " required placeholder="Message" v-model="t_contact.t_message"></textarea>
                                                        <i class="fs-input-icon sl-icon-envolope"></i>
                                                    </div>                                                    
                                                    <div class="form-group" id="t_message-alert">
                                                            
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group" id="t_error-alert">
                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="text-center">
                                                        <button name="submit" type="submit" value="Submit" class="btn-half site-button button-lg m-b15" @click.prevent="onSend()">
                                                                <span>Submit</span><em></em>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="contact-info text-black m-b30">
                                        <!-- TITLE START -->
                                        <div class="section-head text-center">
                                            <h2 class="m-b5" data-title="Info">Contact Info</h2>
                                        </div>
                                        <!-- TITLE END -->
                                        <ul> 
                                            <li>                           
                                                <div class="wt-icon-box-wraper center p-b40">
                                                    <div class="icon-md m-b20"><i class="sl-icon-phone"></i></div>
                                                    <div class="icon-content">
                                                        <h5 class="m-t0 font-weight-500">Telephone</h5>
                                                            <p>04324-225566</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>                           
                                                <div class="wt-icon-box-wraper center p-b40">
                                                    <div class="icon-md m-b20"><i class="sl-icon-phone"></i></div>
                                                    <div class="icon-content">
                                                        <h5 class="m-t0 font-weight-500">Phone number</h5>
                                                            <p>+91 96297 47999</p>
                                                    </div>
                                                </div>
                                            </li>                                            
                                            <li>
                                                <div class="wt-icon-box-wraper center p-b40">
                                                    <div class="icon-md m-b20"><i class="sl-icon-envolope-letter"></i></div>
                                                    <div class="icon-content">
                                                        <h5 class="m-t0 font-weight-500">Email address</h5>
                                                        <p>support@krvmeridian.com</p>
                                                    </div>
                                                </div>
                                            </li>                                            
                                            <li>
                                                <div class="wt-icon-box-wraper center">
                                                    <div class="icon-md m-b20"><i class="sl-icon-map"></i></div>
                                                    <div class="icon-content">
                                                        <h5 class="m-t0 font-weight-500">Address infod</h5>
                                                        <p> 440/1C2B, Karur-Erode Main Road, KP Nagar, Karur, Tamil Nadu - 639008.</p>
                                                    </div>
                                                </div>
                                            </li>                                            
                                        </ul>    
                                    </div>                                        
                                </div>
                            </div> 
                        </div>                               
                            <!-- SITE Search -->
                        <div id="search"> 
                            <span class="close"></span>
                            <form role="search" id="searchform" action="/search" method="get" class="radius-xl">
                                <div class="input-group">
                                    <input value="" name="q" type="search" placeholder="Type to search"/>
                                    <span class="input-group-btn"><button type="button" class="search-btn"><i class="fa fa-search"></i></button></span>
                                </div>   
                            </form>
                        </div>                                                                                                          
                        <!-- MAIN Vav -->
                        <div class="header-nav navbar-collapse collapse default_scroll" id="mymenu">
                            <ul class=" nav navbar-nav default_scroll" v-on:click="myMenu">
                                <li>
                                    <a href="/">Home</a>                                                   
                                </li>
                                <li>
                                    <a href="/guest-rooms">Guest Rooms</a>                                                                            
                                </li>                                         
                                <li class="submenu-direction">
                                    <router-link to="/dinning">Dinning</router-link>                                       
                                </li>
                                <li>
                                    <a href="/party-hall">Party Halls</a>                                          
                                </li>
                                <li class="submenu-direction">
                                    <a href="/gym">Gym</a>                                   
                                </li>
                                <!--<li class="submenu-direction">
                                    <a href="/game-zone">Game Zone</a>                                   
                                </li>-->
                                <li class="submenu-direction">
                                    <router-link to="/contact-us">Contact Us</router-link>                                   
                                </li>                                
                            </ul>
                        </div>                            
                    </div>
                </div>
            </div>
        </header>
        <!-- HEADER END -->        
    </div>
</template>
<script>
// import NavItems from './NavItems.vue'
import axios from 'axios'
export default {
    data(){
		return{
			t_contact:{
				t_username:null,
                t_mobile:null,
				t_email:null,
                t_message:null
			}			
		}     
	},
    methods:{
		onSend(){
            if(!this.t_contact.t_username){
                document.getElementById("t_username-alert").innerHTML = "<div class='text-danger'>Name is required!</div>";
                return false;
            }else{
                document.getElementById("t_username-alert").innerHTML = "";
            }
            if(!this.t_contact.t_mobile){
                document.getElementById("t_mobile-alert").innerHTML = "<div class='text-danger'>Mobile number is required!</div>";
                return false;
            }else{
                document.getElementById("t_mobile-alert").innerHTML = "";
            }
            if(!this.t_contact.t_email){
                document.getElementById("t_email-alert").innerHTML = "<div class='text-danger'>Email ID is required!</div>";
                return false;
            }else{
                document.getElementById("t_email-alert").innerHTML = "";
            }
            if(!this.t_contact.t_message){
                document.getElementById("t_message-alert").innerHTML = "<div class='text-danger'>Please Enter a message!</div>";
                return false;
            }else{
                document.getElementById("t_message-alert").innerHTML = "";
            }
            var obj = new Object();
            obj['name']     = this.t_contact.t_username;
            obj['mobile']   = this.t_contact.t_mobile;
            obj['email']    = this.t_contact.t_email;
            obj['message']  = this.t_contact.t_message;
            
            var myJSON = JSON.stringify(obj);
            document.getElementById("t_error-alert").innerHTML = "<div class='alert-msg infomsg'><div class='text-primary'>Processing...</div></div>";
			axios.post("http://vue.krvmeridian.com/api/contacts.php","data="+myJSON)
			.then((response)=>{			 
                var data = response.data;          
                if(data.message == "Success"){
                    document.getElementById("t_error-alert").innerHTML = "<div class='alert-msg successmsg'><div class='text-success'>Message Sent Successfully!</div></div>";                
                    setTimeout(function(){ location.reload(); },2000);
                }else{
                    document.getElementById("t_error-alert").innerHTML = "<div class='alert-msg errormsg'><div class='text-danger'>Message Not Sent!</div></div>";
                }          
			})
			.catch((error)=>{
				console.log(error);
			}) 
		},
        myMenu: function() {
            var element = document.getElementById("mymenu");
            element.classList.remove("in");
        }
	}
}
/*export default {
    methods: {
        myMenu: function() {
            var element = document.getElementById("mymenu");
            element.classList.remove("in");
        }
    }
    // props:{ 
    //     menuItems:{
    //       type: Array,
    //       required: true,
    //     }
    // },
    // components: { NavItems },
}*/
</script>