<template>
    <div class="page-content"> 
        <Banner />
        <Room />
    </div>
</template>
<script>
import Banner from './banner.vue'
import Room from './room.vue'
export default {
    components:{
        Banner, Room
    }
}
</script>