<template>
    <!-- WELCOME SECTION START -->
    <div class="section-full p-tb90 bg-white overflow-hide">
        <div class="container">
            <div class="section-content">
                <div class="row">
                    <div class="col-md-6 col-sm-12 text-black">
                        <!-- TITLE START -->
                        <div class="section-head text-left">
                            <h2 class=" m-b5" data-title="About">About KRV Meridian</h2>
                            <div class="wt-separator-outer">
                                <div class="wt-separator bg-primary"></div>
                            </div>
                        </div>
                        <!-- TITLE END -->                             
                        <h4 class=" m-t0">
                            A home away from home. 
                        </h4>
                        <p>Stylish and sophisticated KRV Meridian furnishes an experience beyond imagination luxury and take away memories. KRV Meridian is a weave of ethnic grandeur and Modern facilities. Our hotel is located in a prime loaclitiy which is 1.7km (3 mins) from the high way</p>
                        <div class="row equal-wraper">
                            <div class="col-md-6 col-sm-6 m-b30">
                                <div class="wt-icon-box-wraper left bg-gray p-a20 hover-box-effect v-icon-effect  equal-col">
                                    <div class="icon-md m-b20">
                                        <span class="icon-cell"><i class="flaticon-room-service v-icon"></i></span>
                                    </div>
                                    <div class="icon-content">
                                        <h4 class="wt-tilte">Restaurants</h4>
                                        <p>Fill the plate with a variety of cuisine to experience the taste of our delicious food.</p>
                                    </div>
                                </div>                                         
                            </div>  
                            <div class="col-md-6 col-sm-6 m-b30">
                                <div class="wt-icon-box-wraper left bg-gray p-a20 hover-box-effect v-icon-effect  equal-col">
                                    <div class="icon-md m-b20">
                                        <span class="icon-cell"><i class="sl-icon-game-controller v-icon"></i></span>
                                    </div>
                                    <div class="icon-content">
                                        <h4 class="wt-tilte ">GameZone</h4>
                                        <p>Give a break from your daily routine and add fun with gameplays.</p>
                                    </div>
                                </div> 
                            </div>
                            <div class="col-md-6 col-sm-6  m-b30">
                                <div class="wt-icon-box-wraper left bg-gray p-a20 hover-box-effect v-icon-effect  equal-col">
                                    <div class="icon-md m-b20">
                                        <span class="icon-cell"><i class="flaticon-swimmer v-icon"></i></span>
                                    </div>
                                    <div class="icon-content">
                                        <h4 class="wt-tilte ">Swimming pool</h4>
                                        <p>Feel refreshed with dives and swim with your family and friends.</p>
                                    </div>
                                </div>                                         
                            </div>
                            <div class="col-md-6 col-sm-6  m-b30">
                                <div class="wt-icon-box-wraper left bg-gray p-a20 hover-box-effect v-icon-effect  equal-col">
                                    <div class="icon-md m-b20">
                                        <span class="icon-cell"><i class="flaticon-exercise v-icon"></i></span>
                                    </div>
                                    <div class="icon-content">
                                        <h4 class="wt-tilte ">Gym</h4>
                                        <p>Take no break from bringing flexibility and maintain your daily workout in our space.</p>
                                    </div>
                                </div> 
                            </div>  
                            <div class="col-md-6 col-sm-6  m-b30">
                                <div class="wt-icon-box-wraper left bg-gray p-a20 hover-box-effect v-icon-effect  equal-col">
                                    <div class="icon-md m-b20">
                                        <span class="icon-cell"><i class="flaticon-cheers v-icon"></i></span>
                                    </div>
                                    <div class="icon-content">
                                        <h4 class="wt-tilte ">Bar</h4>
                                        <p>Cheers with closed ones are always special. Enjoy your cheers in our bar with good service.</p>
                                    </div>
                                </div> 
                            </div> 
                            <div class="col-md-6 col-sm-6  m-b30">
                                <div class="wt-icon-box-wraper left bg-gray p-a20 hover-box-effect v-icon-effect  equal-col">
                                    <div class="icon-md m-b20">
                                        <span class="icon-cell"><i class="sl-icon-cup v-icon"></i></span>
                                    </div>
                                    <div class="icon-content">
                                        <h4 class="wt-tilte ">Cafeteria</h4>
                                        <p>Spend a little extra time with a sip of hot drinks and tasty snacks served with love.</p>
                                    </div>
                                </div> 
                            </div>                                   
                        </div>                            
                    </div>                    
                    <div class="col-md-6 col-sm-12">
                        <div class="home-about-block-outer bg-repeat bg-white" style="background-image:url(images/background/bg-dot.jpg);">
                            <div class="home-about-block-inner">
                                <div class="home-about-slider owl-carousel owl-btn-vertical-center">                                
                                    <div class="item">
                                        <div class="home-about-slider-pic">
                                            <img src="images/homepage/krv-meridian-hotel-karur-photos-1.jpg" alt="Photos of KRV Meridian hotel, karur " />
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="home-about-slider-pic">
                                            <img src="images/homepage/krv-meridian-hotel-karur-photos-2.jpg" alt="Photos of KRV Meridian hotel, karur " />
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="home-about-slider-pic">
                                            <img src="images/homepage/krv-meridian-hotel-karur-photos-3.jpg" alt="Photos of KRV Meridian hotel, karur " />
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="home-about-slider-pic">
                                            <img src="images/homepage/krv-meridian-hotel-karur-photos-4.jpg" alt="Photos of KRV Meridian hotel, karur " />
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="home-about-slider-pic">
                                            <img src="images/homepage/krv-meridian-hotel-karur-photos-5.jpg" alt="Photos of KRV Meridian hotel, karur " />
                                        </div>
                                    </div>                                                                                                                                                                              
                                </div>  
                            </div>	
                        </div>
                    </div>                                 
                </div>
            </div>
        </div>
    </div>   
    <!-- WELCOME  SECTION END -->  
</template>
<script>
export default {
    
}
</script>