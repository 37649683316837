import Vue from 'vue'
import Router from 'vue-router'
import VueMeta from 'vue-meta'

import Home from '../views/Home.vue'
import GuestRooms from '../views/Guest-room.vue'
import DeluxeRoom from '../components/Deluxe-Room.vue'
import SuperDeluxeRoom from '../components/Super-Deluxe-Room.vue'
import SuiteRoom from '../components/Suite-Room.vue'
import Dinning from '../views/Dinning.vue'
import Gym from '../views/Gym.vue'
import ContactUs from '../views/Contact-Us.vue'
import Partyhall from '../views/Party-hall.vue'
import Gamezone from '../views/Game-zone.vue'

Vue.use(Router)
Vue.use(VueMeta)

const router = new Router({    
    mode:'history',
    routes: [
        {
            path:'/',
            component:Home,
            name:'home'
        },
        {
            path:'/guest-rooms',
            component:GuestRooms,
            name:'guestrooms'
        },
        {
            path:'/deluxe-room-detail',
            component:DeluxeRoom,
            name:'deluxeroom'
        },
        {
            path:'/super-deluxe-room-detail',
            component:SuperDeluxeRoom,
            name:'superdeluxeroom'
        },
        {
            path:'/suite-room-detail',
            component:SuiteRoom,
            name:'suiteroom'
        },
        {
            path:'/dinning',
            component:Dinning,
            name:'dinning'
        },
        {
            path:'/gym',
            component:Gym,
            name:'gym'
        },
        {
            path:'/party-hall',
            component:Partyhall,
            name:'partyhall'
        },
        {
            path:'/game-zone',
            component:Gamezone,
            name:'gamezone'
        },
        {
            path:'/contact-Us',
            component:ContactUs,
            name:'contactus'
        },
    ],
})

export default router