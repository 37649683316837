<template>
    <Guestroom />
</template>
<script>
import Guestroom from '../components/Guest-Rooms/index.vue'
export default {
    components:{
        Guestroom
    },
    metaInfo: {
        title: 'Top Class Guest Rooms at KRV Meridian Hotel, Karur',
        meta: [           
            { name: 'Author', content: 'KRV Meridian'},
            { name: 'Copyright', content: 'Roftr'},
            { name: 'Classification', content: 'Hotel'},
            { name: 'robots', content: 'index,follow'},
            { name: 'DC.title', content: 'KRV Meridian'},
            { name: 'geo.region', content: 'IN-TN'},
            { name: 'geo.placename', content: 'Karur'},
            { name: 'geo.position', content: '10.9706844;78.0602846'},
            { name: 'ICBM', content: '10.9706844, 78.0602846'},
            
            { property: 'og:title', content: 'KRV Meridian Hotel in Karur'},
            { property: 'og:type', content: 'website'},
            { property: 'og:description', content: 'Standard Rooms, Deluxe, Super Deluxe and Suite rooms available in KRV Meridian Hotel, Karur.'},
            { property: 'og:url', content: 'https://www.krvmeridian.com/'},
            { property: 'og:image', content: 'https://www.krvmeridian.com/images/homepage/slide1.jpg'},
            
            { name: 'twitter:card', content: 'summary_large_image'},
            { name: 'twitter:title', content: 'KRV Meridian Hotel in Karur'},
            { property: 'twitter:domain', content: 'https://www.krvmeridian.com'},
            { property: 'twitter:url', content: 'https://www.krvmeridian.com/'},
            { name: 'twitter:description', content: 'Standard Rooms, Deluxe, Super Deluxe and Suite rooms available in KRV Meridian Hotel, Karur.'},
            { name: 'twitter:image', content: 'https://www.krvmeridian.com/images/homepage/slide1.jpg'}
        ],
    },
}
</script>