<template>
    <div class="page-content"> 
        <Slider />
        <Welcome />
        <Room />
      
        <Testimonials />
    </div>
</template>
<script>
import Slider from './slider.vue'
import Welcome from './welcome.vue'
import Room from './room.vue'
//import Services from './services.vue'
import Testimonials from './testimonials.vue'
export default {
    components:{
        Slider, Welcome, Room, Testimonials  
    }
}
</script>