<template>
    <div id="app"> 
      <div class="page-wraper"> 
          <AppHeader />
          <router-view />
          <AppFooter />    
      </div> 
    </div>
</template>

<script>
import AppHeader from './components/AppHeader'
import AppFooter from './components/AppFooter'

export default {
    name: 'App',
      components: {
          AppHeader,
          AppFooter
      },
      /*data(){
          return {
              navs:[
                  {
                      name:'Home',                    
                      path:'/',                   
                  },
                  {
                      name:'Guest Rooms',                    
                      path:'/guest-rooms',
                  },
                  {
                      name:'Dinning',                    
                      path:'/dinning',
                  },
                  {
                      name:'Party Halls',                    
                      path:'/party-hall',
                  },
                  {
                      name:'Gym',                    
                      path:'/gym',
                  },
                  {
                      name:'Game Zone',                    
                      path:'/game-zone',
                  },
                  {
                      name:'Contact Us',                    
                      path:'/contact-us',
                  }
              ]
          }
      }*/
}
</script>

