<template>
    <div class="section-full p-tb90 bg-gray">
        <div class="container">                    
            <!-- GALLERY CONTENT START -->
            <div class="portfolio-wrap mfp-gallery work-grid row clearfix">
                <!-- COLUMNS 1 -->
                <div class="masonry-item cat-1  col-md-4 col-sm-6 m-b30">
                    <div class="wt-box ">
                        <div class="wt-thum-bx  img-center-icon  wt-img-overlay2">
                            <img src="images/gym/gym-photo-1.jpg" alt="gym-photo" />
                            <div class="overlay-bx">
                                <div class="overlay-icon">
                                    
                                    <a class="mfp-link" href="images/gym/gym-photo-1.jpg">
                                        <i class="fa fa-arrows-alt wt-icon-box-xs"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>                                       
                </div>
                <!-- COLUMNS 2 -->
                <div class="masonry-item cat-2 col-md-4 col-sm-6 m-b30">
                    <div class="wt-box ">
                        <div class="wt-thum-bx img-center-icon  wt-img-overlay2">
                            <img src="images/gym/gym-photo-2.jpg" alt="gym photo" />
                            <div class="overlay-bx">
                                <div class="overlay-icon">
                                    
                                    <a class="mfp-link" href="images/gym/gym-photo-2.jpg">
                                        <i class="fa fa-arrows-alt wt-icon-box-xs"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
                <!-- COLUMNS 3 -->
                <div class="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                    <div class="wt-box ">
                        <div class="wt-thum-bx img-center-icon  wt-img-overlay2">
                            <img src="images/gym/gym-photo-3.jpg" alt="gym photo"/>
                            <div class="overlay-bx">
                                <div class="overlay-icon">
                                    
                                    <a class="mfp-link" href="images/gym/gym-photo-3.jpg">
                                        <i class="fa fa-arrows-alt wt-icon-box-xs"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
                <!-- COLUMNS 4 -->
                <div class="masonry-item cat-4 col-md-4 col-sm-6 m-b30">
                        <div class="wt-box ">
                        <div class="wt-thum-bx img-center-icon  wt-img-overlay2">
                            <img src="images/gym/gym-photo-4.jpg" alt="gym photo"/>
                            <div class="overlay-bx">
                                <div class="overlay-icon">
                                    
                                    <a class="mfp-link" href="images/gym/gym-photo-4.jpg">
                                        <i class="fa fa-arrows-alt wt-icon-box-xs"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
                <!-- COLUMNS 5 -->
                <div class="masonry-item cat-5 col-md-4 col-sm-6 m-b30">
                    <div class="wt-box ">
                        <div class="wt-thum-bx img-center-icon  wt-img-overlay2">
                            <img src="images/gym/gym-photo-5.jpg" alt="gym photo"/>
                            <div class="overlay-bx">
                                <div class="overlay-icon">
                                    
                                    <a class="mfp-link" href="images/gym/gym-photo-5.jpg">
                                        <i class="fa fa-arrows-alt wt-icon-box-xs"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>     
                </div>
                <!-- COLUMNS 6 -->
                <div class="masonry-item cat-4 col-md-4 col-sm-6 m-b30">
                        <div class="wt-box ">
                        <div class="wt-thum-bx img-center-icon  wt-img-overlay2">
                            <img src="images/gym/gym-photo-6.jpg" alt="gym photo"/>
                            <div class="overlay-bx">
                                <div class="overlay-icon">
                                    
                                    <a class="mfp-link" href="images/gym/gym-photo-6.jpg">
                                        <i class="fa fa-arrows-alt wt-icon-box-xs"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>     
                </div>
                <!-- COLUMNS 7 -->
                <div class="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                        <div class="wt-box ">
                        <div class="wt-thum-bx img-center-icon  wt-img-overlay2">
                            <img src="images/gym/gym-photo-7.jpg" alt="gym photo"/>
                            <div class="overlay-bx">
                                <div class="overlay-icon">
                                    
                                    <a class="mfp-link" href="images/gym/gym-photo-7.jpg">
                                        <i class="fa fa-arrows-alt wt-icon-box-xs"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
                <!-- COLUMNS 5 -->
                <div class="masonry-item cat-2 col-md-4 col-sm-6 m-b30">
                        <div class="wt-box ">
                        <div class="wt-thum-bx img-center-icon  wt-img-overlay2">
                            <img src="images/gym/gym-photo-8.jpg" alt="gym photo"/>
                            <div class="overlay-bx">
                                <div class="overlay-icon">
                                    
                                    <a class="mfp-link" href="images/gym/gym-photo-8.jpg">
                                        <i class="fa fa-arrows-alt wt-icon-box-xs"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
                <!-- COLUMNS 6 -->
                <div class="masonry-item cat-1 col-md-4 col-sm-6 m-b30">
                        <div class="wt-box ">
                        <div class="wt-thum-bx img-center-icon  wt-img-overlay2">
                            <img src="images/gym/gym-photo-9.jpg" alt="gym photo"/>
                            <div class="overlay-bx">
                                <div class="overlay-icon">
                                    
                                    <a class="mfp-link" href="images/gym/gym-photo-9.jpg">
                                        <i class="fa fa-arrows-alt wt-icon-box-xs"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>                                   
                </div>
            <!-- GALLERY CONTENT END -->                    
            </div>

    
        </div>
        <!-- SECTION CONTENT END  -->

    </div>
</template>
<script>
export default {
    
}
</script>