<template>
    <!-- ROOMS SLIDER START -->
    <div class="section-full p-tb90 bg-gray">
        <div class="container">
            <!-- TITLE START -->
            <div class="section-head text-center">
                <h2 class="m-b5" data-title="Suites">Our Guest Rooms</h2>
                <div class="wt-separator-outer">
                    <div class="wt-separator bg-primary"></div>
                </div>
            </div>
            <!-- TITLE END -->                    
            <div class="text-center">
                <ul class="btn-filter-wrap2">
                    <li class="btn-filter btn-active" data-filter="*">All Rooms</li>
                    <li class="btn-filter" data-filter=".col-1">Standard</li>
                    <li class="btn-filter" data-filter=".col-2">Deluxe</li>
                    <li class="btn-filter" data-filter=".col-3">Super Deluex</li>
                    <li class="btn-filter" data-filter=".col-4">Suite Rooms</li>                        
                </ul>
            </div>                    
        </div>                                 
        <div class="container-fluid">
            <!-- IMAGE CAROUSEL START -->
            <div class="section-content">
                <div class="owl-carousel owl-carousel-filter2 owl-btn-bottom-center">
                       
                    
                      <!-- Standard Rooms 1 --> 
                    <div class="item col-1">
                        <div class="room-rent-section-outer">
                            <div class="room-rent-section">
                                <div class="rooms-pic-section">
                                    <div class="wt-media">
                                        <img src="images/guest-rooms/krv-meridian-standard-room-photos-1.jpg" alt="krv meridian standard room" />
                                        <div class="overlay-bx-3"></div>
                                        <h3 class="m-b0 wt-title">Standard Room</h3>
                                    </div>
                                </div>
                                <!--<div class="room-info-section text-black">
                                    <span>$399.00/night</span>
                                    <ul class="clearfix">
                                        <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m² </li>
                                        <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3 </li>
                                        <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony </li>
                                    </ul>
                                </div>-->
                            </div>
                            <!--<a href="/super-deluxe-room-detail" class="btn-half site-button button-lg"><span>More</span><em></em></a>-->
                        </div>
                    </div>  
                    <!-- Standard Rooms 1 --> 
                    
                    
                      <!-- Standard Rooms 2 --> 
                    <div class="item col-1">
                        <div class="room-rent-section-outer">
                            <div class="room-rent-section">
                                <div class="rooms-pic-section">
                                    <div class="wt-media">
                                        <img src="images/guest-rooms/krv-meridian-standard-room-photos-2.jpg" alt="krv meridian standard room" />
                                        <div class="overlay-bx-3"></div>
                                        <h3 class="m-b0 wt-title">Standard Room</h3>
                                    </div>
                                </div>
                                <!--<div class="room-info-section text-black">
                                    <span>$399.00/night</span>
                                    <ul class="clearfix">
                                        <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m² </li>
                                        <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3 </li>
                                        <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony </li>
                                    </ul>
                                </div>-->
                            </div>
                            <!--<a href="/super-deluxe-room-detail" class="btn-half site-button button-lg"><span>More</span><em></em></a>-->
                        </div>
                    </div>  
                    <!-- Standard Rooms 2 --> 
                    
                     <!-- Standard Rooms 3 --> 
                    <div class="item col-1">
                        <div class="room-rent-section-outer">
                            <div class="room-rent-section">
                                <div class="rooms-pic-section">
                                    <div class="wt-media">
                                        <img src="images/guest-rooms/krv-meridian-standard-room-photos-3.jpg" alt="krv meridian standard room" />
                                        <div class="overlay-bx-3"></div>
                                        <h3 class="m-b0 wt-title">Standard Room</h3>
                                    </div>
                                </div>
                                <!--<div class="room-info-section text-black">
                                    <span>$399.00/night</span>
                                    <ul class="clearfix">
                                        <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m² </li>
                                        <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3 </li>
                                        <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony </li>
                                    </ul>
                                </div>-->
                            </div>
                            <!--<a href="/super-deluxe-room-detail" class="btn-half site-button button-lg"><span>More</span><em></em></a>-->
                        </div>
                    </div>  
                    <!-- Standard Rooms 3 --> 
                    
                    <!--  Deluxe Room 1 --> 
                    <div class="item col-2">
                        <div class="room-rent-section-outer">
                            <div class="room-rent-section">
                                <div class="rooms-pic-section">
                                    <div class="wt-media">
                                        <img src="images/guest-rooms/krv-meridian-deluxe-room-photos-1.jpg" alt="KRV Meridian Deluxe Room"/>
                                        <div class="overlay-bx-3"></div>
                                        <h3 class="m-b0 wt-title">Deluxe Room</h3> 
                                    </div>
                                </div>
                                <!--<div class="room-info-section text-black">
                                    <span>$299.00/night</span>
                                    <ul class="clearfix">
                                        <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m² </li>
                                        <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3 </li>
                                        <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony </li>
                                    </ul>
                                </div>-->
                            </div>
                            <!--<a href="/deluxe-room-detail" class="btn-half site-button button-lg"><span>More</span><em></em></a>-->
                        </div>
                    </div>   
                    <!--  Deluxe Room 1 --> 
                    
                    <!--  Deluxe Room 2 --> 
                    <div class="item col-2">
                        <div class="room-rent-section-outer">
                            <div class="room-rent-section">
                                <div class="rooms-pic-section">
                                    <div class="wt-media">
                                        <img src="images/guest-rooms/krv-meridian-deluxe-room-photos-2.jpg" alt="KRV Meridian Deluxe Room"/>
                                        <div class="overlay-bx-3"></div>
                                        <h3 class="m-b0 wt-title">Deluxe Room</h3> 
                                    </div>
                                </div>
                                <!--<div class="room-info-section text-black">
                                    <span>$299.00/night</span>
                                    <ul class="clearfix">
                                        <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m² </li>
                                        <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3 </li>
                                        <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony </li>
                                    </ul>
                                </div>-->
                            </div>
                            <!--<a href="/deluxe-room-detail" class="btn-half site-button button-lg"><span>More</span><em></em></a>-->
                        </div>
                    </div>   
                    <!--  Deluxe Room 2 --> 
                                          
                    <!-- Super Deluxe 1 --> 
                    <div class="item col-3">
                        <div class="room-rent-section-outer">
                            <div class="room-rent-section">
                                <div class="rooms-pic-section">
                                    <div class="wt-media">
                                        <img src="images/guest-rooms/krv-meridian-super-deluxe-room-photos-1.jpg" alt="KRV Meridian Super Deluxe Room" />
                                        <div class="overlay-bx-3"></div>
                                        <h3 class="m-b0 wt-title">Super Deluex Room</h3>
                                    </div>
                                </div>
                                <!--<div class="room-info-section text-black">
                                    <span>$399.00/night</span>
                                    <ul class="clearfix">
                                        <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m² </li>
                                        <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3 </li>
                                        <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony </li>
                                    </ul>
                                </div>-->
                            </div>
                            <!--<a href="/super-deluxe-room-detail" class="btn-half site-button button-lg"><span>More</span><em></em></a>-->
                        </div>
                    </div>  
                    <!-- Super Deluxe 1 --> 
                    
                     <!-- Super Deluxe 2 --> 
                    <div class="item col-3">
                        <div class="room-rent-section-outer">
                            <div class="room-rent-section">
                                <div class="rooms-pic-section">
                                    <div class="wt-media">
                                        <img src="images/guest-rooms/krv-meridian-super-deluxe-room-photos-2.jpg" alt="KRV Meridian Super Deluxe Room" />
                                        <div class="overlay-bx-3"></div>
                                        <h3 class="m-b0 wt-title">Super Deluex Room</h3>
                                    </div>
                                </div>
                                <!--<div class="room-info-section text-black">
                                    <span>$399.00/night</span>
                                    <ul class="clearfix">
                                        <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m² </li>
                                        <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3 </li>
                                        <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony </li>
                                    </ul>
                                </div>-->
                            </div>
                            <!--<a href="/super-deluxe-room-detail" class="btn-half site-button button-lg"><span>More</span><em></em></a>-->
                        </div>
                    </div>  
                    <!-- Super Deluxe 2 --> 
                    
                     <!-- Super Deluxe 3 --> 
                    <div class="item col-3">
                        <div class="room-rent-section-outer">
                            <div class="room-rent-section">
                                <div class="rooms-pic-section">
                                    <div class="wt-media">
                                        <img src="images/guest-rooms/krv-meridian-super-deluxe-room-photos-3.jpg" alt="KRV Meridian Super Deluxe Room" />
                                        <div class="overlay-bx-3"></div>
                                        <h3 class="m-b0 wt-title">Super Deluex Room</h3>
                                    </div>
                                </div>
                                <!--<div class="room-info-section text-black">
                                    <span>$399.00/night</span>
                                    <ul class="clearfix">
                                        <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m² </li>
                                        <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3 </li>
                                        <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony </li>
                                    </ul>
                                </div>-->
                            </div>
                            <!--<a href="/super-deluxe-room-detail" class="btn-half site-button button-lg"><span>More</span><em></em></a>-->
                        </div>
                    </div>  
                    <!-- Super Deluxe 3 --> 
                    
                     <!-- Super Deluxe 4 --> 
                    <div class="item col-3">
                        <div class="room-rent-section-outer">
                            <div class="room-rent-section">
                                <div class="rooms-pic-section">
                                    <div class="wt-media">
                                        <img src="images/guest-rooms/krv-meridian-super-deluxe-room-photos-4.jpg" alt="KRV Meridian Super Deluxe Room" />
                                        <div class="overlay-bx-3"></div>
                                        <h3 class="m-b0 wt-title">Super Deluex Room</h3>
                                    </div>
                                </div>
                                <!--<div class="room-info-section text-black">
                                    <span>$399.00/night</span>
                                    <ul class="clearfix">
                                        <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m² </li>
                                        <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3 </li>
                                        <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony </li>
                                    </ul>
                                </div>-->
                            </div>
                            <!--<a href="/super-deluxe-room-detail" class="btn-half site-button button-lg"><span>More</span><em></em></a>-->
                        </div>
                    </div>  
                    <!-- Super Deluxe 4 --> 
                    
                                                 
                    <!-- Suite Rooms 1 --> 
                    <div class="item col-4">
                        <div class="room-rent-section-outer">
                            <div class="room-rent-section">
                                <div class="rooms-pic-section">
                                    <div class="wt-media">
                                        <img src="images/guest-rooms/krv-meridian-suite-room-photos-1.jpg" alt="KRV Meridian Suite Rooms" />
                                        <div class="overlay-bx-3"></div>
                                        <h3 class="m-b0 wt-title">Suite Rooms</h3>
                                    </div>
                                </div>
                                <!--<div class="room-info-section text-black">
                                    <span>$299.00/night</span>
                                    <ul class="clearfix">
                                        <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m² </li>
                                        <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3 </li>
                                        <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony </li>
                                    </ul>
                                </div>-->
                            </div>
                            <!--<a href="/suite-room-detail" class="btn-half site-button button-lg"><span>More</span><em></em></a>-->
                        </div>
                    </div> 
                    <!-- Suite Rooms 1 --> 
                    
                     <!-- Suite Rooms 2 --> 
                    <div class="item col-4">
                        <div class="room-rent-section-outer">
                            <div class="room-rent-section">
                                <div class="rooms-pic-section">
                                    <div class="wt-media">
                                        <img src="images/guest-rooms/krv-meridian-suite-room-photos-2.jpg" alt="KRV Meridian Suite Rooms" />
                                        <div class="overlay-bx-3"></div>
                                        <h3 class="m-b0 wt-title">Suite Rooms</h3>
                                    </div>
                                </div>
                                <!--<div class="room-info-section text-black">
                                    <span>$299.00/night</span>
                                    <ul class="clearfix">
                                        <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m² </li>
                                        <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3 </li>
                                        <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony </li>
                                    </ul>
                                </div>-->
                            </div>
                            <!--<a href="/suite-room-detail" class="btn-half site-button button-lg"><span>More</span><em></em></a>-->
                        </div>
                    </div> 
                    <!-- Suite Rooms 2 --> 
                    
                     <!-- Suite Rooms 3 --> 
                    <div class="item col-4">
                        <div class="room-rent-section-outer">
                            <div class="room-rent-section">
                                <div class="rooms-pic-section">
                                    <div class="wt-media">
                                        <img src="images/guest-rooms/krv-meridian-suite-room-photos-3.jpg" alt="KRV Meridian Suite Rooms" />
                                        <div class="overlay-bx-3"></div>
                                        <h3 class="m-b0 wt-title">Suite Rooms</h3>
                                    </div>
                                </div>
                                <!--<div class="room-info-section text-black">
                                    <span>$299.00/night</span>
                                    <ul class="clearfix">
                                        <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m² </li>
                                        <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3 </li>
                                        <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony </li>
                                    </ul>
                                </div>-->
                            </div>
                            <!--<a href="/suite-room-detail" class="btn-half site-button button-lg"><span>More</span><em></em></a>-->
                        </div>
                    </div> 
                    <!-- Suite Rooms 3 --> 
                    
                     <!-- Suite Rooms 4 --> 
                    <div class="item col-4">
                        <div class="room-rent-section-outer">
                            <div class="room-rent-section">
                                <div class="rooms-pic-section">
                                    <div class="wt-media">
                                        <img src="images/guest-rooms/krv-meridian-suite-room-photos-4.jpg" alt="KRV Meridian Suite Rooms" />
                                        <div class="overlay-bx-3"></div>
                                        <h3 class="m-b0 wt-title">Suite Rooms</h3>
                                    </div>
                                </div>
                                <!--<div class="room-info-section text-black">
                                    <span>$299.00/night</span>
                                    <ul class="clearfix">
                                        <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m² </li>
                                        <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3 </li>
                                        <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony </li>
                                    </ul>
                                </div>-->
                            </div>
                            <!--<a href="/suite-room-detail" class="btn-half site-button button-lg"><span>More</span><em></em></a>-->
                        </div>
                    </div> 
                    <!-- Suite Rooms 4 --> 
                    
                     <!-- Suite Rooms 5 --> 
                    <div class="item col-4">
                        <div class="room-rent-section-outer">
                            <div class="room-rent-section">
                                <div class="rooms-pic-section">
                                    <div class="wt-media">
                                        <img src="images/guest-rooms/krv-meridian-suite-room-photos-5.jpg" alt="KRV Meridian Suite Rooms" />
                                        <div class="overlay-bx-3"></div>
                                        <h3 class="m-b0 wt-title">Suite Rooms</h3>
                                    </div>
                                </div>
                                <!--<div class="room-info-section text-black">
                                    <span>$299.00/night</span>
                                    <ul class="clearfix">
                                        <li><i class="fa fa-expand"></i> <strong>Size:</strong> 30m² </li>
                                        <li><i class="fa fa-user"></i> <strong>Adult:</strong> 3 </li>
                                        <li><i class="fa fa-eye"></i> <strong>View:</strong> balcony </li>
                                    </ul>
                                </div>-->
                            </div>
                            <!--<a href="/suite-room-detail" class="btn-half site-button button-lg"><span>More</span><em></em></a>-->
                        </div>
                    </div> 
                    <!-- Suite Rooms 5 --> 
                                             
                </div>
            </div>
        </div>                
    </div>   
    <!-- ROOMS  SLIDER END -->
</template>
<script>
export default {
    
}
</script>