<template>
    <Gym />
</template>
<script>
import Gym from '../components/Gym/index.vue'
export default {
    components:{
        Gym
    },
    
    metaInfo() {
    return {
      title: "Title comes from Gymvue file",
      meta: [
        
        {name: 'description', content:  'Epiloge is about connecting in your'},

        // Twitter Card
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Vue Social Cards Example'},
        {name: 'twitter:description', content: 'Vue sample site showing off Twitter and Facebook Cards.'},
        {name: 'twitter:image', content: this.logo},

        // Facebook OpenGraph
        {property: 'og:title', content: 'Vue Social Cards Example'},
        {property: 'og:site_name', content: 'Vue Example'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  this.logo},
        {property: 'og:description', content: 'Vue sample site showing off Twitter and Facebook Cards.'}
      ]
    }
  }
}
</script>