<template>
    <!-- TITLE START -->
    <div class="section-head text-center">
        <h2 class="m-b5">Game zone at KRV Meridian</h2> 
        <div class="wt-separator-outer">
            <div class="wt-separator bg-primary"></div>
        </div>
        <p>Game zone a heaven for gamers with vibrant colours and ultimate design theme apt for your bold clan or kids .You are sure going to spend guaranteed hours of fun Unlimited.</p>
    </div>
    <!-- TITLE END -->    
</template>
<script>
export default {
    
}
</script>