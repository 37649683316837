<template>
    <div class="page-content">
        <Banner />
        <br />
        <Title />
        <Content />
    </div>
</template>
<script>
import Banner from './banner.vue'
import Title from './title.vue'
import Content from './content.vue'
export default {
    components:{
        Banner, Title, Content
    }
}
</script>