<template>
    <!-- OUR STORY SECTION START -->
    <div class="section-full bg-gray p-tb90 ">
        <div class="container">
            <!-- TITLE START -->
            <div class="section-head text-center">
                <h1 class="m-b5">Dinning at <strong>GLACE</strong>, KRV Meridian.</h1>
                <div class="wt-separator-outer">
                    <div class="wt-separator bg-primary"></div>
                </div>
                    <p>The <strong>GLACE</strong> our best gourmet expert sets you up a scrumptious breakfast utilising the best and seasonal ingredients. The adaptable menu for lunch and dinner flaunts some imaginative food that keeps guest raving. All dishes under our roof served with love and delicacy...</p>
            </div>
            <!-- TITLE END -->                	
            <div class="section-content our-story">
            
                <div class="row">
                    <div class="col-md-6 col-sm-6">
                        <div class="our-story-pic-block">
                            <div class="notification-animate">	
                                <div class="wt-media our-story-pic wt-img-effect zoom-slow">
                                    <img src="images/dinning/breakfast-krv-meridian.jpg" alt="breakfast-krv-meridian">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <div class="wt-box our-story-detail">
                            <h4>Morning 7am to 9am</h4>
                            <h3 class=" m-b10">Breakfast</h3>
                            <p>Halt your quick bite from your busy daily schedule and enjoy our delicious breakfast with no hurryburries.</p>
                        </div>
                    </div>
                </div> 
                    
                <div class="row">
                    <div class="col-md-6 col-sm-6">
                        <div class="our-story-pic-block">
                            <div class="notification-animate">
                                <div class="wt-media our-story-pic wt-img-effect zoom-slow">
                                    <img src="images/dinning/lunch-krv-meridian.jpg" alt="lunch-krv-meridian">
                                </div>
                            </div>
                        </div>
                    </div>                        
                    <div class="col-md-6 col-sm-6">
                        <div class="wt-box our-story-detail">
                            <h4>Noon 12pm to 3pm</h4>
                            <h3 class=" m-b10">Lunch</h3>
                            <p>Taste the variety of appetising cuisine prepared and served with tenderness.</p>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-6 col-sm-6">
                        <div class="our-story-pic-block">
                            <div class="notification-animate">
                                <div class="wt-media our-story-pic wt-img-effect zoom-slow">
                                    <img src="images/dinning/dinner-krv-meridian.jpg" alt="dinner-krv-meridian">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <div class="wt-box our-story-detail">
                            <h4>Night 7pm to 11pm</h4>
                            <h3 class=" m-b10">Dinner</h3>
                            <p>End your day with a favourite dish, dining comfortably by recollecting the memories with your dear ones.</p>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-6 col-sm-6">
                        <div class="our-story-pic-block">
                            <div class="notification-animate">
                                <div class="wt-media our-story-pic wt-img-effect zoom-slow">
                                    <img src="images/dinning/hyde-out-krv-meridian-hotel-photo-1.jpg" alt="hyde out bar krv meridian hotel karur" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <div class="wt-box our-story-detail">
                            <h4>11am to 11pm</h4>
                            <h3 class=" m-b10">HYDE OUT</h3>
                            <p>Forget the negatives and set your mind free with joyful shots. Spend your time in good vibes and music.</p>
                        </div>
                    </div>                            
                </div>
                                                    
            </div>
        </div>
        
        
    </div>   
    <!-- OUR STORY SECTION END -->  
</template>
<script>
export default {
    
}
</script>