import { render, staticRenderFns } from "./testimonials.vue?vue&type=template&id=599905de&"
import script from "./testimonials.vue?vue&type=script&lang=js&"
export * from "./testimonials.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports