<template>
    <div class="section-full p-tb80">
        <!-- LOCATION BLOCK-->
        <div class="container">
        
            <!-- GOOGLE MAP & CONTACT FORM -->
            <div class="section-content">
                <!-- CONTACT FORM-->
                <div class="row">
                    <div class="col-md-4 col-sm-6">
                        <div class="contact-info text-black m-b30">
                            <!-- TITLE START -->
                            <div class="section-head text-left">
                                <h2 class="m-b5">Contact Info</h2>
                            </div>
                            <!-- TITLE END -->  
                             <div class="wt-icon-box-wraper left p-b40">
                                <div class="icon-xs"><i class="fa fa-phone"></i></div>
                                <div class="icon-content">
                                    <h5 class="m-t0 font-weight-500">Mobile</h5>
                                    <p>+919629747999</p> 
                                </div>                                
                            </div>                             
                            <div class="wt-icon-box-wraper left p-b40">
                                <div class="icon-xs"><i class="fa fa-phone"></i></div>
                                <div class="icon-content">
                                    <h5 class="m-t0 font-weight-500">Mobile</h5>
                                    <p>+919629434999</p>
                                </div>                                
                            </div> 
                            
                            <div class="wt-icon-box-wraper left p-b40">
                                <div class="icon-xs"><i class="fa fa-envelope"></i></div>
                                <div class="icon-content">
                                    <h5 class="m-t0 font-weight-500">Email address</h5>
                                    <p>support@krvmeridian.com</p>
                                </div>
                            </div>
                            
                            <div class="wt-icon-box-wraper left">
                                <div class="icon-xs"><i class="fa fa-map-marker"></i></div>
                                <div class="icon-content">
                                    <h5 class="m-t0 font-weight-500">Address info</h5>
                                    <p> 45, Karur-Erode Main Road, <br/> KP Nagar, Karur, Tamil Nadu - 639008.</p>
                                </div>
                            </div>
                        </div>
                    </div>                        
                    <div class="col-md-8 col-sm-6">
                        <form class="contact-form cons-contact-form" method="post" action="form-handler.php" autocomplete="off">
                            <div class="contact-one m-b30">
                            
                                <!-- TITLE START -->
                                <div class="section-head text-left">
                                    <h2 class="m-b5">Get In Touch</h2>
                                </div>
                                <!-- TITLE END -->  
                                                                
                                <div class="form-group">
                                    <input name="username" id="username" type="text" required class="form-control" v-model="contact.username" placeholder="Name">
                                </div>
                                <div class="form-group" id="username-alert">
                                        
                                </div>
                                <div class="form-group">
                                    <input name="mobile" id="mobile" type="text" v-model="contact.mobile" required class="form-control" placeholder="Mobile">
                                </div>
                                <div class="form-group" id="mobile-alert">
                                        
                                </div>
                                <div class="form-group">
                                    <input name="email" id="email" type="text" v-model="contact.email" class="form-control" required placeholder="Email">
                                </div>
                                <div class="form-group" id="email-alert">
                                        
                                </div>
                                <div class="form-group">
                                    <textarea name="message" id="message" rows="4" class="form-control" v-model="contact.message" required placeholder="Enquiry Message"></textarea>
                                </div>
                                <div class="form-group" id="message-alert">
                                        
                                </div>
                                <div class="form-group" id="error-alert">
                                        
                                </div>
                                <div class="text-right">
                                    <button name="submit" type="submit" value="Submit" class="btn-half site-button button-lg m-b15"  @click.prevent="onSend()">
                                            <span>Submit</span><em></em>
                                    </button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>                    
            <div class="gmap-outline">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1017.438101473063!2d78.03789561298063!3d10.975252324269734!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x30f731147c246e2f!2sKRV%20Meridian%20Hotel!5e1!3m2!1sen!2sin!4v1642421474265!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>                    
        </div>
    </div>        
    <!-- SECTION CONTENT END -->  
</template>
<script>
import axios from 'axios'
export default {
    data(){
		return{
			contact:{
				username:null,
                mobile:null,
				email:null,
                message:null
			}			
		}     
	},
    methods:{
		onSend(){
            if(!this.contact.username){
                document.getElementById("username-alert").innerHTML = "<div class='text-danger'>Name is required!</div>";
                return false;
            }else{
                document.getElementById("username-alert").innerHTML = "";
            }
            if(!this.contact.mobile){
                document.getElementById("mobile-alert").innerHTML = "<div class='text-danger'>Mobile number is required!</div>";
                return false;
            }else{
                document.getElementById("mobile-alert").innerHTML = "";
            }
            if(!this.contact.email){
                document.getElementById("email-alert").innerHTML = "<div class='text-danger'>Email ID is required!</div>";
                return false;
            }else{
                document.getElementById("email-alert").innerHTML = "";
            }
            if(!this.contact.message){
                document.getElementById("message-alert").innerHTML = "<div class='text-danger'>Please Enter a message!</div>";
                return false;
            }else{
                document.getElementById("message-alert").innerHTML = "";
            }
            var obj = new Object();
            obj['name']     = this.contact.username;
            obj['mobile']   = this.contact.mobile;
            obj['email']    = this.contact.email;
            obj['message']  = this.contact.message;
            
            var myJSON = JSON.stringify(obj);
            document.getElementById("error-alert").innerHTML = "<div class='alert-msg infomsg'><div class='text-primary'>Processing...</div></div>";
			axios.post("http://vue.krvmeridian.com/api/contacts.php","data="+myJSON)
			.then((response)=>{			 
                var data = response.data;          
                if(data.message == "Success"){
                    document.getElementById("error-alert").innerHTML = "<div class='alert-msg successmsg'><div class='text-success'>Message Sent Successfully!</div></div>";                
                    setTimeout(function(){ location.reload(); },2000);
                }else{
                    document.getElementById("error-alert").innerHTML = "<div class='alert-msg errormsg'><div class='text-danger'>Message Not Sent!</div></div>";
                }          
			})
			.catch((error)=>{
				console.log(error);
			}) 
		}
	}
}
</script>