<template>
    <div class="section-full p-tb90 bg-gray">
        <div class="container">    
         <!-- TITLE START -->
            <div class="section-head text-center">
                <h2 class="m-b5" data-title="Food">Restaurant Gallery</h2>
                <div class="wt-separator-outer">
                    <div class="wt-separator bg-primary"></div>
                </div>
            </div>
            <!-- TITLE END -->                 
            <!-- GALLERY CONTENT START -->
            <div class="portfolio-wrap mfp-gallery work-grid row clearfix">
            
           
            
                <!-- COLUMNS 1 -->
                <div class="masonry-item cat-1  col-md-4 col-sm-6 m-b30">
                    <div class="wt-box ">
                        <div class="wt-thum-bx  img-center-icon  wt-img-overlay2">
                          <img src="images/dinning/krv-meridian-hotel-glace-restaurant-photo-1.jpg" alt="Glace Restaurant, krv meridian Hotel Karur." />
                            <div class="overlay-bx">
                                <div class="overlay-icon">
                                    
                                    <a class="mfp-link" href="images/dinning/krv-meridian-hotel-glace-restaurant-photo-1.jpg">
                                        <i class="fa fa-arrows-alt wt-icon-box-xs"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>                                       
                </div>
                 <div class="masonry-item cat-1  col-md-4 col-sm-6 m-b30">
                    <div class="wt-box ">
                        <div class="wt-thum-bx  img-center-icon  wt-img-overlay2">
                          <img src="images/dinning/krv-meridian-hotel-glace-restaurant-photo-2.jpg" alt="Glace Restaurant, krv meridian Hotel Karur." />
                            <div class="overlay-bx">
                                <div class="overlay-icon">
                                    
                                    <a class="mfp-link" href="images/dinning/krv-meridian-hotel-glace-restaurant-photo-2.jpg">
                                        <i class="fa fa-arrows-alt wt-icon-box-xs"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>                                       
                </div>
                <div class="masonry-item cat-1  col-md-4 col-sm-6 m-b30">
                    <div class="wt-box ">
                        <div class="wt-thum-bx  img-center-icon  wt-img-overlay2">
                          <img src="images/dinning/krv-meridian-hotel-glace-restaurant-photo-3.jpg" alt="Glace Restaurant, krv meridian Hotel Karur." />
                            <div class="overlay-bx">
                                <div class="overlay-icon">
                                    
                                    <a class="mfp-link" href="images/dinning/krv-meridian-hotel-glace-restaurant-photo-3.jpg">
                                        <i class="fa fa-arrows-alt wt-icon-box-xs"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>                                       
                </div>
                <!-- COLUMNS 2 -->
                <div class="masonry-item cat-2 col-md-4 col-sm-6 m-b30">
                    <div class="wt-box ">
                        <div class="wt-thum-bx img-center-icon  wt-img-overlay2">
                            <img src="images/dinning/hyde-out-krv-meridian-hotel-photo-1.jpg" alt="Hyde Out Bar, krv meridian Hotel Karur." />
                            <div class="overlay-bx">
                                <div class="overlay-icon">
                                    
                                    <a class="mfp-link" href="images/dinning/hyde-out-krv-meridian-hotel-photo-1.jpg">
                                        <i class="fa fa-arrows-alt wt-icon-box-xs"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
                <!-- COLUMNS 3 -->
                <div class="masonry-item cat-3 col-md-4 col-sm-6 m-b30">
                    <div class="wt-box ">
                        <div class="wt-thum-bx img-center-icon  wt-img-overlay2">
                            <img src="images/dinning/hyde-out-krv-meridian-hotel-photo-2.jpg" alt="Hyde Out Bar, krv meridian Hotel Karur." />
                            <div class="overlay-bx">
                                <div class="overlay-icon">
                                    
                                    <a class="mfp-link" href="images/dinning/hyde-out-krv-meridian-hotel-photo-2.jpg">
                                        <i class="fa fa-arrows-alt wt-icon-box-xs"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
                <!-- COLUMNS 4 -->
                <div class="masonry-item cat-4 col-md-4 col-sm-6 m-b30">
                        <div class="wt-box ">
                        <div class="wt-thum-bx img-center-icon  wt-img-overlay2">
                            <img src="images/dinning/krv-meridian-hotel-roof-top-restaurant-photo.jpg" alt="roof top restaurant, krv meridian Hotel Karur." />
                            <div class="overlay-bx">
                                <div class="overlay-icon">
                                    
                                    <a class="mfp-link" href="images/dinning/krv-meridian-hotel-roof-top-restaurant-photo.jpg">
                                        <i class="fa fa-arrows-alt wt-icon-box-xs"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
                <!-- COLUMNS 5 -->
                
                
            <!-- GALLERY CONTENT END -->                    
            </div>

    
        </div>
        <!-- SECTION CONTENT END  -->

    </div>
</template>
<script>
export default {
    
}
</script>