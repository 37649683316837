<template>
    <!-- TESTIMONIALS SECTION START -->
    <div class="section-full p-t80 p-b80  overlay-wraper" data-stellar-background-ratio="0.5" style="background-image:url(images/homepage/home-testimonials-bg.jpg);">
        <div class="overlay-main opacity-05 bg-black"></div>
        <div class="container">
            <div class="section-content">            
                <!-- TITLE START -->
                <div class="section-head text-left">
                    <h2 class="m-b5 text-white" data-title="Clients">Our Client Says</h2>
                    <div class="wt-separator-outer">
                        <div class="wt-separator bg-primary"></div>
                    </div>
                </div>
                <!-- TITLE END -->             
                <!-- TESTIMONIAL 4 START ON BACKGROUND -->   
                <div class="section-content">
                    <div class="testimonial-home owl-carousel  owl-btn-top-right">
                        <div class="item">
                            <div class="testimonial-6">
                                <div class="testimonial-pic-block"> 
                                    <div class="testimonial-pic">
                                        <img src="images/homepage/blank.png" width="132" height="132" alt="" />
                                    </div>
                                </div>
                                <div class="testimonial-text clearfix text-white">
                                    <div class="testimonial-detail ">
                                        <h4 class="testimonial-name m-t0 m-b10">Neethi G</h4>
                                    </div>
                                    <div class="testimonial-paragraph text-black p-t5">
                                        <p>It's a star hotel combined with a restaurant called Glace. Dishes offered in glace were delicious and worth the money. Rooms available here were clean and tidy. Service by people working here was so kind and gentle. They are upgrading the place with swimming pool and gym.</p>
                                    </div>
                                    <div class="testimonial-detail ">
                                        <span class="testimonial-position">From Google Review</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="testimonial-6">
                                <div class="testimonial-pic-block"> 
                                    <div class="testimonial-pic">
                                        <img src="images/homepage/blank.png" width="132" height="132" alt="">
                                    </div>
                                </div>
                                <div class="testimonial-text clearfix text-white">
                                    <div class="testimonial-detail ">
                                        <h4 class="testimonial-name m-t0 m-b10">Amit Singh</h4>
                                    </div>
                                    <div class="testimonial-paragraph text-black p-t5">
                                        <p>I stayed in this hotel for 2 nights and 3 days and enjoyed every bit of it. The hotel staffs are extremely humble and responds very quickly. It's swimming pool was extremely clean, rooftop restaurant was very good, foods were really a delicacies and room service was superior.</p>
                                    </div>
                                    <div class="testimonial-detail ">
                                        <span class="testimonial-position">From Google Review</span>
                                    </div>
                                </div>
                            </div>
                        </div>  
                        <div class="item">
                            <div class="testimonial-6">
                                <div class="testimonial-pic-block"> 
                                    <div class="testimonial-pic">
                                        <img src="images/homepage/blank.png" width="132" height="132" alt="">
                                    </div>
                                </div>
                                <div class="testimonial-text clearfix text-white">
                                    <div class="testimonial-detail ">
                                        <h4 class="testimonial-name m-t0 m-b10">Saravanan</h4>
                                    </div>
                                    <div class="testimonial-paragraph text-black p-t5">
                                        <p>Good hotel, newly constructed. Neat and clean. Hotel is having swimming pool on the roof top. The stay was awesome. Food was also very good.</p>
                                    </div>
                                    <div class="testimonial-detail ">
                                        <span class="testimonial-position">From Google Review</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                                                                                 
                    </div>
                </div>
            </div>
        </div>
    </div>   
    <!-- TESTIMONIALS SECTION END --> 
</template>
<script>
export default {
    
}
</script>