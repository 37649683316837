<template>
    <Partyhall />
</template>
<script>
import Partyhall from '../components/Party-Hall/index.vue'
export default {
    components:{
        Partyhall
    },
      
    metaInfo() {
    return {
      title: "Harmony Hall and KRV Mahal, KRV Meridian Karur.",
      description: "KRV Mahal with 500 Guest, KRV Harmony Hall with 250 Guest with Veg and Non-Veg food facilities,KRV Meridian Karur. ",
      meta: [
        { name: 'Author', content: 'KRV Meridian'},
        { name: 'Copyright', content: 'Roftr'},
        { name: 'Classification', content: 'Hotel'},
        { name: 'robots', content: 'index,follow'},
        { name: 'DC.title', content: 'KRV Meridian'},
        { name: 'geo.region', content: 'IN-TN'},
        { name: 'geo.placename', content: 'Karur'},
        { name: 'geo.position', content: '10.9706844;78.0602846'},
        { name: 'ICBM', content: '10.9706844, 78.0602846'},
        {name: 'description', content:  'KRV Mahal with 500 Guest, KRV Harmony Hall with 250 Guest with Veg and Non-Veg food facilities,KRV Meridian Karur.'},

        // Twitter Card
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Harmony Hall and KRV Mahal, KRV Meridian Karur.'},
        {name: 'twitter:description', content: 'KRV Mahal with 500 Guest, KRV Harmony Hall with 250 Guest with Veg and Non-Veg food facilities,KRV Meridian Karur.'},
        {name: 'twitter:image', content: 'https://www.krvmeridian.com/images/party-halls/krv-mahal-karur-big.jpg'},

        // Facebook OpenGraph
        {property: 'og:title', content: 'Harmony Hall and KRV Mahal, KRV Meridian Karur.'},
        {property: 'og:site_name', content: 'KRV Meridian'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content: 'https://www.krvmeridian.com/images/party-halls/krv-mahal-karur-big.jpg'},
        {property: 'og:description', content: 'KRV Mahal with 500 Guest, KRV Harmony Hall with 250 Guest with Veg and Non-Veg food facilities,KRV Meridian Karur.'}
      ]
    }
  }
  
  
}
</script>