<template>
    <div>
        <!-- FOOTER START -->
        <footer class="site-footer footer-large footer-light footer-wide">            
            <!-- FOOTER BLOCKES START -->  
            <div class="footer-top overlay-wraper">
                <div class="overlay-main"></div>
                <div class="container">                
                    <div class="row">
                        <!-- ABOUT COMPANY -->
                        <div class="col-lg-3 col-md-6 col-sm-6">  
                            <div class="widget widget_about">
                                <!--<h4 class="widget-title">About Company</h4>-->
                                <div class="logo-footer clearfix p-b15">
                                    <a href="/"><img src="images/logo-dark.png" alt="krv meridian Logo"/></a>
                                </div>
                                
                                  <p class="max-w400">We will be so proud to be our guest. Hope you would love the ambience. The facilities available are the best in class and we look forward to your pleasant stay.</p>                                

                                <ul class="social-icons social-tooltips-outer  wt-social-links">
                                    <li><a href="javascript:void(0);" class="fa fa-facebook"><span class="social-tooltips">Facebook</span></a></li>
                                    <li><a href="javascript:void(0);" class="fa fa-twitter"><span class="social-tooltips">Twitter</span></a></li>                            
                                    <li><a href="javascript:void(0);" class="fa fa-linkedin"><span class="social-tooltips">Linkedin</span></a></li>
                                    <li><a href="javascript:void(0);" class="fa fa-instagram"><span class="social-tooltips">Instagram</span></a></li>
                                </ul>                                    
                            </div>
                        </div>                      
                       <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="widget widget_services inline-links">
                                <h4 class="widget-title">Useful links</h4>
                                <ul>
                                    <li><a href="/">Home</a></li>                                    
                                    <li><a href="/dinning">DINNING</a></li>
                                    <li><a href="/party-hall">PARTY HALLS</a></li>
                                    <li><a href="/gym">GYM </a></li>
                                    <li><a href="/game-zone">GAME ZONE</a></li>
                                                                     
                                </ul>
                            </div>                           
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                             <div class="widget widget_services inline-links">
                                <h4 class="widget-title">Guest Rooms</h4>
                                <ul>                                    
                                    <!--
                                    <li><a href="/deluxe-room-detail">Deluxe</a></li>
                                    <li><a href="/super-deluxe-room-detail">Super Deluxe</a></li>
                                    <li><a href="/suite-room-detail">Suite Rooms</a></li>
                                    -->  
                                    <li><a href="/guest-rooms">Standard</a></li>
                                    <li><a href="/guest-rooms">Deluxe</a></li>
                                    <li><a href="/guest-rooms">Super Deluxe</a></li>
                                    <li><a href="/guest-rooms">Suite</a></li>                                                                    
                                </ul>
                            </div>                          
                        </div>                        
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="widget widget_address_outer">
                                <h4 class="widget-title">Contact Us</h4>
                                <ul class="widget_address"> 
                                    <li><i class="sl-icon-map text-primary"></i> 45, Karur-Erode Main Road,  <br />KP Nagar, Karur, <br /> Tamil Nadu - 639008.</li>
                                    <li><i class="sl-icon-envolope-letter text-primary"></i>support@krvmeridian.com</li>
                                    <li><i class="sl-icon-phone text-primary"></i>+919629747999, +919629434999</li>                                    
                                </ul>                           
                            </div>                                                  
                        </div>                           
                    </div>
                </div>
            </div>
            <!-- FOOTER COPYRIGHT -->
            <div class="footer-bottom overlay-wraper">
                <div class="overlay-main"></div>
                <div class="container">
                    <div class="row">
                        <div class="wt-footer-bot-center">
                            <span class="copyrights-text">Copyright © 2023 KRV Meridian, Karur. All Rights Reserved. Powered by <a href="https://www.roftr.com" target="_blank" rel="noreferrer" title="Hotel Website Development Company">Roftr</a>.</span>
                            
                            
                    
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- FOOTER END -->        
        <!-- BUTTON TOP START -->
		<button class="scroltop"><span class="fa fa-angle-up  relative" id="btn-vibrate"></span></button> 
        <!-- LOADING AREA START ===== -->
        <div class="loading-area">
            <div class="loading-box"></div>
            <div class="loading-pic">
                <div class="cssload-thecube">
                    <div class="cssload-cube cssload-c1"></div>
                    <div class="cssload-cube cssload-c2"></div>
                    <div class="cssload-cube cssload-c4"></div>
                    <div class="cssload-cube cssload-c3"></div>
                </div>
            </div>
        </div>
        <!-- LOADING AREA  END ====== -->
    </div>
</template>
<script>
export default {
    
}
</script>                                
