<template>
    <div class="page-content"> 
        <Banner />
        <Content />
    </div>
</template>
<script>
import Banner from './banner.vue'
import Content from './content.vue'
export default {
    components:{
        Banner, Content
    }
}
</script>